import { Toaster } from '@/components/ui/sonner'
import { AppProps } from 'next/app'
import '../styles/global.css'

import { wrapper } from '@/state/store'
import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { Provider } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'

const INTERCOM_APP_ID = 'zlm5my4a'

import React from 'react'

function MyApp({ Component, pageProps }: AppProps) {
	const { store, props } = wrapper.useWrappedStore(pageProps)

	useEffect(() => {
		hotjar.initialize(3392123, 6)
	})

	return (
		<div>
			<IntercomProvider appId={INTERCOM_APP_ID}>
				<Provider store={store}>
					<Component {...pageProps} />
					<Toaster />
				</Provider>
			</IntercomProvider>
		</div>
	)
}

export default MyApp
