import { socket } from '@/socket'
import { schemaSlice } from '@/state/features/schema/schemaSlice'
import { combineSlices, configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'
import { projectSlice } from './features/project/projectSlice'
import { structureSlice } from './features/structure/structureSlice'

const rootReducer = combineSlices(projectSlice, schemaSlice, structureSlice)
export const makeStore = () => {
	return configureStore({
		reducer: rootReducer,
		devTools: process.env.NODE_ENV !== 'production',
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				thunk: {
					extraArgument: { socket },
				},
			}),
	})
}

export type AppStore = ReturnType<typeof makeStore>
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']

export const wrapper = createWrapper<AppStore>(makeStore)
