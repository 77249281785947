var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fa9af62b97cf112d38b142f9d986fea8afb4d76b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const sentryEnabled = process.env.NODE_ENV !== 'development'
const sentryDsn =
	process.env.SENTRY_DSN ||
	process.env.NEXT_PUBLIC_SENTRY_DSN ||
	'https://87b74efd030747648bc173e6c2d397b7@o1050986.ingest.sentry.io/4504756799733760'

Sentry.init({
	dsn: sentryDsn,
	tracesSampleRate: 1.0,
	enabled: sentryEnabled,
})
