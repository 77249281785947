import { ClientToServerEvents, ServerToClientEvents } from '@contember-cloud/studio-server'
import { Socket, io } from 'socket.io-client'

const isBrowser = typeof window !== 'undefined'
const socketServer = process.env.NEXT_PUBLIC_SOCKET_SERVER

if (!socketServer) {
	throw new Error('NEXT_PUBLIC_SOCKET_SERVER is not defined')
}

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> | undefined = isBrowser ? io(socketServer) : undefined
