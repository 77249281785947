import { addStmtToSchemaDsl, setSchemaDsl } from '@/state/features/schema/schemaSlice'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { z } from 'zod'
import { RootState } from '../../store'
import { addStmtToStructureDsl, setStructure, setStructureDsl } from '../structure/structureSlice'

export type ProjectType = string

const pluginDependency = z.object({
	name: z.string(),
	version: z.string(),
	type: z.union([z.literal('production'), z.literal('development')]),
})

export type PluginDependency = z.infer<typeof pluginDependency>

const plugin = z.object({
	id: z.string(),
	organizationId: z.string().nullable(),
	name: z.string(),
	slug: z.string(),
	description: z.string().nullable(),
	createdAt: z.string(),
	archivedAt: z.string().nullable(),
	activeRelease: z
		.object({
			id: z.string(),
			type: z.union([z.literal('field_attached'), z.literal('basic')]),
			description: z.string().nullable(),
			dependencies: z.array(pluginDependency).nullable(),
			schema: z.string().nullable(),
			handler: z.string(),
			handlerArgs: z.string().nullable(),
			createdAt: z.string(),
		})
		.nullable(),
})

export const projectSliceState = z.object({
	id: z.string().optional(),
	personId: z.string().optional(),
	intent: z.string().optional(),
	streamingStep: z
		.union([z.literal('applicationName'), z.literal('applicationType'), z.literal('intentStatus'), z.literal('whatIsWrong')])
		.optional(),
	name: z.string().optional().nullable(),
	organizationId: z.string().optional().nullable(),
	type: z.string().optional().nullable(),
	specification: z.string().optional().nullable(),
	repositoryId: z.string().optional().nullable(),
	cloudAdminUrl: z.string().optional().nullable(),
	cloudSelfcareUrl: z.string().optional().nullable(),
	showDebugger: z.boolean().optional().nullable(),
	showOptionsPanel: z.boolean().optional().nullable(),
	isSaved: z.boolean().nullable(),
	isStreaming: z.boolean(),
	isDownloadable: z.boolean().nullable().optional(),
	isCodeAvailable: z.boolean().optional().nullable(),
	plugins: z.array(plugin),
})

export type ProjectSliceState = z.infer<typeof projectSliceState>
export type Plugin = z.infer<typeof plugin>

const initialState: ProjectSliceState = {
	id: undefined,
	personId: undefined,
	isStreaming: false,
	isSaved: true,
	plugins: [],
}

export const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		setProjectId(state, action: PayloadAction<string>) {
			state.id = action.payload
		},
		setProjectIntent(state, action: PayloadAction<string>) {
			state.intent = action.payload
			state.isSaved = false
		},
		setProjectName(state, action: PayloadAction<string>) {
			state.name = action.payload
			state.isSaved = false
		},
		setProjectType(state, action: PayloadAction<ProjectType | undefined>) {
			state.type = action.payload
			state.isSaved = false
		},
		setProjectSpecification(state, action: PayloadAction<string>) {
			state.specification = action.payload
			state.isSaved = false
		},
		resetProject() {
			return initialState
		},
		setProjectIsSaved(state, action: PayloadAction<boolean>) {
			state.isSaved = action.payload
		},
		setProject(state, action: PayloadAction<ProjectSliceState>) {
			return action.payload
		},
		setShowDebugger(state, action: PayloadAction<boolean>) {
			state.showDebugger = action.payload
		},
		setProjectIsStreaming(state, action: PayloadAction<boolean>) {
			state.isStreaming = action.payload
		},
		setProjectOrganizationId(state, action: PayloadAction<string>) {
			state.organizationId = action.payload
		},
		setPlugins(state, action: PayloadAction<Plugin[]>) {
			state.plugins = action.payload
		},
	},
	extraReducers: builder => {
		builder
			.addCase(setStructureDsl.fulfilled, (state, action) => {
				if (!state.isStreaming) {
					state.isDownloadable = true
				}
			})
			.addCase(addStmtToSchemaDsl, (state, action) => {
				state.isSaved = false
			})
			.addCase(setSchemaDsl, (state, action) => {
				state.isSaved = false
			})
			.addCase(setStructure, (state, action) => {
				state.isSaved = false
			})
			.addCase(addStmtToStructureDsl, (state, action) => {
				state.isSaved = false
			})
	},
})

export const {
	setProjectId,
	setProjectName,
	setProjectType,
	setProjectIsSaved,
	resetProject,
	setProject,
	setShowDebugger,
	setProjectIsStreaming,
	setProjectOrganizationId,
	setProjectIntent,
	setProjectSpecification,
} = projectSlice.actions

export const selectProjectState = (state: RootState) => state.project

export default projectSlice.reducer
